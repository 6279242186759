import React, { useState } from 'react'
import GatsbyImage from 'gatsby-image'
import Lightbox from 'fslightbox-react'
import Masonry from 'react-responsive-masonry'
import MasonryResponsive from 'react-responsive-masonry/lib/ResponsiveMasonry'

export default ({ images, photographer }) => {
  const imageWidth = images.length > 1 ? 'w-1/2' : 'w-auto'
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  })

  const openLightboxOnSlide = number => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number + 1,
    })
  }
  if (images.length === 0) {
    return <></>
  }

  return (
    <div className="lg:-mx-24">
      <Lightbox
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        sources={images.map(image => image.fluid.src)}
      />
      <MasonryResponsive columnsCountBreakPoints={{ 300: 1, 576: 2 }}>
        <Masonry columnsCount={2} gutter="10px">
          {images.map((img, index) => (
            <div key={img.fluid.src} onClick={() => openLightboxOnSlide(index)}>
              <GatsbyImage fluid={img.fluid} className="cursor-pointer" />
            </div>
          ))}
        </Masonry>
      </MasonryResponsive>

      {photographer && (
        <p className="italic text-grey">Fotos: {photographer}</p>
      )}
    </div>
  )
}
