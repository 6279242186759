import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import MvGallery from '../components/MvGallery'
import MvTextPage from '../components/common/MvTextPage'
import MvTextPageSection from '../components/common/MvTextPageSection'
import BlogPostStyles from './BlogPost.module.css'

export default ({ data }) => {
  const post = data.markdownRemark
  const galleryImages = data.allFile.edges.map(
    edge => edge.node.childImageSharp
  )
  const aktuellesPage = {
    name: 'Aktuelles',
    path: '/aktuelles',
  }
  return (
    <Layout>
      <MvTextPage
        dataLabel="blog-post"
        className={BlogPostStyles.blogPost}
        parent={aktuellesPage}
      >
        <MvTextPageSection>
          {post.frontmatter.hint && (
            <div className={BlogPostStyles.hint}>{post.frontmatter.hint}</div>
          )}
          <h1 className="text-center mt-8 mx-auto">{post.frontmatter.title}</h1>
          {post.frontmatter.title && (
            <div className="font-sans text-2xl font-normal my-4 text-center">
              {post.frontmatter.subtitle}
            </div>
          )}
          <div className="text-center text-grey text-lg mb-16">
            {post.frontmatter.author && (
              <div>
                von{' '}
                <address className="inline">{post.frontmatter.author}</address>
              </div>
            )}
            <div>
              am <time className="inline">{post.frontmatter.date}</time>
            </div>
          </div>
          <div
            className={BlogPostStyles.blogPost__content + ' mb-16 text-lg'}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <MvGallery
            images={galleryImages}
            photographer={post.frontmatter.photographer}
          />
        </MvTextPageSection>
      </MvTextPage>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!, $imgDir: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        subtitle
        hint
        date(formatString: "D. MMMM YYYY", locale: "de-DE")
        author
        photographer
      }
    }
    allFile(
      filter: { relativeDirectory: { eq: $imgDir } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
